import React from "react"
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import BackgroundImage from "gatsby-background-image";
import Layout from "../components/layout"
import BlogList from '../components/Feed/BlogList';
import SEO from "../components/seo"
import style from "../components/Feed/News.module.css";
import HeroImage from "../components/Hero/HeroImage";
// import GatsbyImage from "gatsby-image";
import { ButtonArrowRight } from "../components/Button";

const Header = ({ data }) => {

    const post  = data.slice(0,1)[0];

    console.log("POST", post)
    const heroBackground = post.heroVideo ? 
        `https://img.youtube.com/vi/${post.heroVideo.split('v=')[1].split('&')[0]}/maxresdefault.jpg`
        : null

    return (
        heroBackground ?
            <div className={`uk-cover-container uk-light uk-flex uk-flex-middle`} style={{backgroundColor: "rgba(29, 38, 54, 0.45)"}}>
                <img
                    src={heroBackground}
                    alt={post.title}
                    className={style.youTubeImage}
                    data-uk-cover
                    data-uk-img
                    data-uk-parallax="opacity: 1,0.1; easing:0" />
                <div id={post.slug} className="uk-container uk-position-relative uk-light">
                    <div className={`${style.topWrapHeight} uk-flex uk-flex-middle`}>
                        <div
                            style={{minWidth: "80%"}}
                            className={`${style.topContainer} uk-position-relative uk-margin-medium-top`}
                            data-uk-parallax="y: 0,50; easing:0; opacity:0.2">
                            <div
                                className={`${style.heroCopy} uk-width-1-2@s uk-position-z-index`}
                                data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150">
                                <h1 style={{fontSize: "2.75rem"}}>{post.title}</h1>
                                <p><Link to={post.title}>The latest</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        :
            <BackgroundImage
                    Tag="div"
                    fluid={post.heroImage.localFile.childImageSharp.fluid}
                    preserveStackingContext={true}
                    style={{
                        marginTop: '72px',
                        minHeight: '520px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundColor: "rgba(29, 38, 54, 0.45)",
                        backgroundPosition: `center center`,
                        display: `flex`,
                        alignItems: `center`,
                        height: `100%`
                      }}
                  >
                  <div id={post.slug} className="uk-container uk-position-relative uk-light">
                      <div className="uk-container">
                      <div className={`${style.topWrapHeight} uk-flex uk-flex-middle`}>
                          <div
                              style={{minWidth: "80%"}}
                              className={`${style.topContainer} uk-position-relative uk-margin-medium-top`}
                              data-uk-parallax="y: 0,50; easing:0; opacity:0.2">
                              <div
                                  className={`${style.heroCopy} uk-width-2-3@s`}
                                  data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150">
                                  <h1 className="h1__large uk-text-wheat-lighter">{post.title}</h1>
                                  <p><ButtonArrowRight
                                    url={`/feed/${post.slug}`}
                                    label="The latest"
                                    css={`uk-button-large cta-button uk-text-wheat-lighter`}
                                    color={`#E4DBCD`} /></p>
                              </div>
                          </div>
                      </div>
                      </div>
                  </div>
            </BackgroundImage>
    )
}

const Blog = ({data}) => {

    let blogData = data.allContentfulBlogPost.nodes;
    blogData.sort((a, b) => new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime());

    return (
        <Layout>
            <SEO title="Articles" />
            <Helmet>
            </Helmet>
            <Header data={blogData} />
            {/* <HeroImage data={blogData[0]} /> */}
            <div style={{minHeight: "100vh"}}>
                <div className={`${style.postList}`}><BlogList data={blogData.slice(1)} /></div>
            </div>

        </Layout>
    )
}

export default Blog;

export const blogPageQuery = graphql`
    query blogPageQuery {
        allContentfulBlogPost(filter: {site: {eq: "SoleraAdvisors"}}) {
                nodes {
                    author
                    topic
                    metaKeywords
                    publishDate
                    title
                    copy {
                        copy
                    }
                    content {
                        json
                    }
                    heroImage {
                        localFile {
                            name
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                  ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    heroVideo
                    slug
                }
        }
    }`
