import React from "react";

export const ButtonArrowRight = ({url, label, color, css}) =>
<a href={url} className={`uk-button uk-button-text rightArrow ${css}`}>
    {label}<span className="">
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <polyline fill="none" stroke={color} points="10 5 15 9.5 10 14" />
            <line fill="none" stroke={color} x1="4" y1="9.5" x2="15" y2="9.5" />
        </svg>
    </span>
</a>