import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import * as Markdown from 'react-markdown';
import style from "./Hero.module.css";

const ImageHeader = ({ media, headline, leader, slug }) => {
    return (
        <BackgroundImage
            Tag="div"
            fluid={media.imageFile.localFile.childImageSharp.fluid}
            preserveStackingContext={true}
            className="uk-height-min-large uk-background-fixed"
            style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundColor: "rgba(29, 38, 54, 0.4)",
                backgroundPosition: `center center`,
                display: `flex`,
                alignItems: `left`,
            }}>
            <div id={slug} className="uk-container uk-position-relative uk-light">
                <div className={`uk-flex uk-flex-middle uk-height-1-1`}>
                    <div className={`uk-container-expand uk-flex uk-flex-left uk-text-left`}>
                        <div className="uk-width-1-1">
                            <h1>{headline}</h1>
                           {leader ? <p className="uk-text-lead" dangerouslySetInnerHTML={{ __html: leader }} /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </BackgroundImage>
    )
}

const FullscreenHeader = ({ media, headline, leader, slug }) => {

    return (
        media.imageFile.file?.contentType?.split('/')[0] === "image" ?
            <BackgroundImage
                Tag="section"
                fluid={media.imageFile.localFile.childImageSharp.fluid}
                preserveStackingContext={true}
                style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: "rgba(29, 38, 54, 0.4)",
                    backgroundPosition: `center center`,
                    display: `flex`,
                    alignItems: `center`,
                    height: `100%`
                }}
                data-uk-height-viewport="offset-top: true;">
                <div id={slug} className="uk-container uk-container-xsmall uk-position-relative uk-light">
                    <div className={`uk-flex uk-flex-middle`}>
                        <div className={`${style.heroCopy} uk-container uk-container-small uk-flex-auto uk-text-center`} data-uk-scrollspy="target: > .animate; cls: uk-animation-slide-bottom-small uk-invisible; delay: 300">
                            <h1 className="uk-heading-primary animate uk-invisible">{headline}</h1>
                            <p  
                                className={`uk-text-large animate uk-invisible`}
                                data-uk-scrollspy-class="uk-animation-fade uk-invisible"
                                dangerouslySetInnerHTML={{ __html: leader }} />
                        </div>
                    </div>
                </div>
            </BackgroundImage>
            :
            <div className="uk-light uk-cover-container" data-uk-height-viewport="offset-top: true;">
                <video
                    style={{ height: "100%", zIndex: "-1" }}
                    className={`uk-video uk-box-shadow-small uk-margin-auto-left uk-margin-small-right`}
                    autoPlay loop data-uk-video data-uk-height-viewport data-uk-cover>
                    <source src={media.imageFile.localFile.publicURL} type="video/mp4" />
                </video>
                <div style={{ backgroundColor: "rgba(29, 38, 54, 0.3)" }} className="uk-position-cover" />
                <div id={slug} className="uk-container uk-position-relative uk-light">
                    <div className={`uk-flex uk-flex-middle uk-height-viewport`}>
                        <div className={`${style.heroCopy} uk-container uk-container-small uk-flex-auto uk-text-left uk-light`} data-uk-scrollspy="target: > .animate; cls: uk-animation-slide-bottom-small uk-invisible; delay: 300">
                            <h1 className="h1__xlarge uk-heading-primary animate uk-invisible uk-width-1-2@m">{headline}</h1>
                            {leader ?
                                <p  
                                className={`uk-text-large animate uk-invisible`}
                                data-uk-scrollspy-class="uk-animation-fade uk-invisible"
                                dangerouslySetInnerHTML={{ __html: leader }} />
                                : null}
                        </div>
                    </div>
                </div>
            </div>
    )
}

const HeroImage = ({ data }) => {
    const {
        headline,
        subHeadline,
        slug
    } = data

    const heroCtaMedia = useStaticQuery(graphql`
        query heroCtaMedia {
            allContentfulHeroCta {
                nodes {
                    slug
                    videoPath
                    imageFile {
                        file {
                            contentType
                        }
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 2000, quality: 70) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }`);

    const leader = subHeadline ? subHeadline : false
    const media = heroCtaMedia.allContentfulHeroCta.nodes.find(d => d.slug === slug)

    return (
        data.templateLayout === "Fullscreen" ?
            <FullscreenHeader
                media={media}
                headline={headline}
                leader={leader}
                slug={slug} />
            :
            <ImageHeader
                media={media}
                headline={headline}
                leader={leader}
                slug={slug} />
    )
}

export default HeroImage;
