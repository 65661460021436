import React, { useMemo } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { ButtonArrowRight } from "../Button";
import style from "./News.module.css";
import eye from "../../images/icons/Eye.png";

const BlogListItem = props => {
    const {
        topic,
        title,
        copy,
        heroImage,
        heroVideo,
        slug
    } = props.blog;

    const posterImage = useMemo(() => {
        if (heroVideo) {
            return `https://img.youtube.com/vi/${heroVideo.split("v=")[1].split("&")[0]}/maxresdefault.jpg`;
        }
    }, [heroVideo]);

    return (
        <div className='uk-margin-bottom' data-topic={topic}>
            <div className="uk-card uk-card-default uk-border uk-border-earth-lightest uk-border-rounded uk-card-small uk-overflow-hidden uk-box-shadow-small">
                <div className="uk-card-media-top uk-position-relative uk-height-medium">
                    <div className={`${style.overlayBackground} uk-transition-fade uk-position-cover uk-overlay uk-flex uk-flex-center uk-flex-middle`}>
                        <Link
                            style={{ textDecoration: 'none' }}
                            to={`/feed/${slug}`}>
                            <span>
                                <img
                                    src={eye}
                                    alt={title}
                                    style={{ maxWidth: "72px" }}
                                />
                            </span>
                        </Link>
                    </div>
                    {posterImage ?
                        <img
                            style={{ objectFit: "cover", objectPosition: "center", width: "100%", height: "auto", minHeight: "100%", maxHeight: "200px" }}
                            src={posterImage} />
                        :
                        heroImage ?
                            <Img
                                style={{ height: "100%", minHeight: "100%" }}
                                fluid={heroImage.localFile.childImageSharp.fluid}
                                alt={title}
                                objectFit="cover"
                                objectPosition="50% 50%"
                            />
                            : <p>image</p>
                    }

                </div>
                <div className="uk-card-body">
                    <p>
                        <Link className="uk-link-text uk-text-medium uk-text-bold"
                            to={`/feed/${slug}`}>
                            {title}
                        </Link>
                    </p>

                    <div>
                        {copy.copy.substr(0, 64)}{copy.copy.length > 64 ? "..." : null}
                    </div>
                </div>

                <div className="uk-card-footer">
                    <ButtonArrowRight
                        url={`/feed/${slug}`}
                        label="Read this"
                        css={`uk-button-small cta-button uk-text-grape`}
                        color={`rgb(96, 74, 113)`} />
                </div>
            </div>
        </div>
    );
};

export default BlogListItem
