import React, { useState } from "react";
import BlogListItem from "./BlogListItem";
import style from "./Filter.module.css";

const BlogList = ({ data }) => {
    const [selected, setSelected] = useState('All Topics');
    const topics = [...new Set(data.map((t) => t.topic))];
    let tags = [];
    const cat = data.map((t) =>
        t.metaKeywords
            ? t.metaKeywords.split(',').map((item) => tags.push(item.trim()))
            : null
    );
    tags = [...new Set(tags)];
    
    return (
        <section className="uk-section">
            <div className="" data-uk-filter="target: .topic-filter">
                {/* <div className="uk-padding uk-width-1-1">
                    <div className="uk-inline">
                        <p
                            style={{ fontSize: '11px', fontWeight: 'bold' }}
                            className="uk-text-muted uk-text-uppercase uk-margin-remove-bottom"
                        >
                            Filter by
                        </p>
                        <div className={`${style.filterButton} uk-button uk-button-small`}>
                            {selected} <span data-uk-icon="icon: chevron-down" />
                        </div>
                        <div
                            className={`${style.filterDropdown} uk-box-shadow-medium uk-border-rounded`}
                            data-uk-toggle
                            data-uk-dropdown="mode: click; animation: uk-animation-slide-bottom-medium; offset: -40"
                        >
                            <ul className={`uk-nav uk-dropdown-nav`}>
                                <li className="uk-active" data-uk-filter-control="">
                                    <a href="#" onClick={() => setSelected('All topics')}>
                                        All Topics
                                    </a>
                                </li>
                                {topics.map((topic) =>
                                    <li key={topic}
                                        data-uk-filter-control={`[data-topic='${topic}']`}>
                                        <a href="#" onClick={() => setSelected(topic)}>{topic}</a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div> */}
                <div className="uk-container uk-padding uk-width-1-1">
                    <div className="topic-filter uk-child-width-1-2@s uk-child-width-1-3@m uk-grid uk-grid-match" uk-height-match="target: .uk-card-body">
                        {data.map(post =>
                            <BlogListItem blog={post} key={post.slug} />
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogList;
